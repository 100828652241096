body {
  color: $hexagon-color-text;
  color: themed("color-text");
  background-color: $hexagon-color-background;
  background-color: themed("color-background");
  background-image: themed("image-background");
  font-family: $base_font_family;
  font-size: $font-size;
  line-height: 1.25em;
  margin: 0 auto;
}

abbr[title=required] {
  display: none;
}

code {
  font-family: monospace;
  font-size: 1.2em;
}

dd {
  margin-bottom: 1em;
}

dt {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $base_font_family;
}

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4, h5, h6 {
  font-size: $h4-size;
}

fieldset {
  margin-bottom: 1em;
}

header {
  margin: 0 0 $padding-050 0;
  padding: 0;
}

img {
  border: none;
  vertical-align: middle;
}

menu, .menu {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0 0.2em;
    list-style-type: none;
    display: inline;
  }
}
.menu {
  font-size: $h3-size;
}

p {
  margin-bottom: 1em;
}

table tfoot {
  margin-top: 2em;
}

.hint {
  color: themed("color-text-muted");
  font-style: italic;
  max-width: 70%;
}

.info {
  color: themed("color-text-muted");
  font-style: italic;
  font-size: 80%;
}
