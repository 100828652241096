
.user-greeting-outer {
  padding: $padding-025 0 $padding-025;
  span.user-greeting {
    color: $user-home-greeting-color;
    cursor: help;
  }
}

div#c-users {
  div#a-show {

    display: flex;
    flex-flow: column;
    gap: 1em;

    & > div {
      max-width: 1600px;
      box-sizing: border-box;
    }

    // Top section: stats and whatnot
    .stats-section {
      display: flex;
      flex-flow: column;

      // Mobile
      @include window-larger-than(800px) {
        flex-flow: row;
      }

      .profile-avatar .post-thumbnail {
        margin: 0 1em 1em 0;
      }

      .profile-stats {
        width: 100%;
        padding: 0 0.5em;
        box-sizing: border-box; // padding overflows the page otherwise
        border-radius: $border-radius-full;
        background: themed("color-section");

        abbr {
          cursor: help;
        }
      }
    }

    .user-statistics {
      display: grid;
      grid-template-columns: 1fr;

      // Mobile
      @include window-larger-than(800px) {
        grid-template-columns: 1fr 1fr;
      }

      .column {
        display: grid;
        grid-template-columns: max-content minmax(150px, 1fr);
        grid-auto-rows: minmax(1.75em, max-content);
        column-gap: 1em;

        > span:nth-child(odd) {
          font-weight: bold;
          text-align: right;
        }
      }
    }

    .staff-notes-section {
      details { margin-bottom: 0; }
    }


    // Middle section: uploads and favorites
    .blacklist-ui { padding: 0; }

    .posts-section {
      display: flex;
      flex-flow: column;
      gap: 1em;

      .profile-sample {
        display: grid;
        grid-template: "p-header"
                       "p-links"
                       "p-posts";
        gap: 0.5em 0;

        @include window-larger-than(800px) {
          grid-template: "p-header p-links"
                         "p-posts p-posts";
          grid-template-columns: 12em 1fr;
          gap: 0 0.5em;
        }
      }

      .profile-sample-header a, .profile-sample-links a {
        display: block;
        box-sizing: border-box;
        align-content: center;
        text-align: center;

        height: 100%;
        padding: 0.5em;
        border-radius: 6px;
        background-color: themed("color-section");

        &:hover {
          background-color: themed("color-section-lighten-5");
        }
        &:focus, &:active {
          outline: 0;
          color: themed("color-link-active");
        }
      }

      .profile-sample-header {
        grid-area: p-header;
        display: flex;

        a {
          font-size: 1.25em;
          font-weight: bold;
          width: 100%;
        }

        @include window-larger-than(800px) {
          a { border-radius: 6px 6px 0 0; }
        }
      }

      .profile-sample-links {
        grid-area: p-links;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        text-align: center;

        span {
          padding: 0.5em;
          color: themed("color-text-muted");
        }

        .spacer { display: none; }
        .profile-comments-link {
          grid-row: 1 / 3;
          grid-column: 3;
        }

        @include window-larger-than(800px) {
          display: flex;
          a { height: min-content; }
          .spacer { display: block; }
        }
      }

      .profile-sample-posts {
        grid-area: p-posts;
        display: flex;
        overflow: auto;
        justify-content: center;
        flex-wrap: wrap;

        gap: 1em;
        padding: 0.5em;

        background: var(--color-section);
        border-radius: 6px;

        // Desktop
        @include window-larger-than(800px) {
          flex-wrap: nowrap;
          border-top-left-radius: 0;
          justify-content: flex-start;
        }
      }
    }


    // Bottom section: about me and commission info
    .about-section {
      display: flex;
      flex-flow: column;
      gap: 1em;

      // Mobile
      @include window-larger-than(800px) {
        flex-flow: row;
      }

      .profile-about-entry {
        flex-grow: 1;
        flex-basis: 50%;

        background-color: themed("color-section");
        border-radius: 6px;
        padding: 0.5em;
      }
    }
  }

  div#a-edit {
    h1 {
      margin: 0.5em 0;
    }

    h2 {
      margin: 0.5em 0;
    }

    div.input {
      margin-bottom: 2em;
    }

    div.input span.hint {
      display: block;
      max-width: 70%;
    }

    .active {
      color: themed("color-link-active");
    }
  }
}

// User signup and login
#c-users #a-new,
#c-sessions #a-new,
#c-maintenance-user-password-resets #a-new,
#c-maintenance-user-login-reminders #a-new {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 360px));
  gap: 1em;

  margin-bottom: 1em;
}

.simple_form.session_form {
  box-sizing: border-box;
  max-width: 360px;
  margin: 0;

  h1 {
    margin-bottom: 0.5em;
    text-align: center;
  }

  div.input {
    input[type="text"], input[type="email"], input[type="password"], select {
      // z_responsive is the absolute worst
      width: 100% !important;
      max-width: unset !important;
      box-sizing: border-box;
    }
  }
}

.session_info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 360px;
  padding: 0.5rem;
  border-radius: 3px;
  background-color: themed("color-section");

  h3 { margin-bottom: 1em; }
}

// Password validation
.password-input {
  input[type="password"] {
    border-radius: 3px 3px 0 0;
  }

  .password-strength {
    width: 100%;
    height: 0.25rem;
    border-radius: 0 0 3px 3px;

    background: white;
    overflow: hidden;
    margin: 0;

    .password-progress {
      background: linear-gradient(to right, palette("text-red") 0%, palette("text-yellow") 25%, palette("text-green") 100%);
      background-size: 360px 100%;

      height: 100%;
      transition: width 1s ease-in-out;
    }
  }

  .password-feedback {
    display: flex;
    flex-flow: column;
    padding-left: 1em;
    margin-top: 0.5em;

    span { display: list-item; }
    .password-warning { font-weight: bold; }
  }
}
