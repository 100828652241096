body.c-posts.a-index, body.c-favorites.a-index {
  #page {
    // Override the theme to instead
    // project it upon the content area
    background: none;
    padding: 0;
  }

  // Exhibit A
  // Makes the content area take up the
  // full height of the page. Yes, really.
  #page, #c-posts, #c-favorites, #a-index {
    // I hate both this and myself
    display: flex;
    flex-flow: column;
    flex: 1;
  }
}

.post-index {
  display: grid;

  grid-template-areas:
    "search " 
    "content" 
    "sidebar";
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;

  flex: 1; // See Exhibit A

  // 1. Searchbox
  .search {
    grid-area: search;

    padding: 0.5rem 0.25rem;
    background-color: #152f56;
    background-color: themed("color-foreground");
    box-shadow: inset 0px -0.25rem 0.25rem -0.25rem themed("color-background");

    h1 {
      font-size: $h3-size;
    }

    .search-controls {
      display: none;
      flex-flow: column;
    }
  }

  // 2. Content
  .content {
    display: flex; // See Exhibit A
    flex-flow: column;

    grid-area: content;

    // Imported from #page
    padding: 0.5rem 0.25rem themed("content-padding-bottom");
    background-color: #152f56;
    background-color: themed("color-foreground");
    background-image: themed("image-foreground");
    background-position: themed("image-foreground-position");
    background-repeat: themed("image-foreground-repeat");

    // Quick tag edit
    #edit-dialog textarea {
      margin-bottom: 0.25rem;
    }

    // Actual content area:
    // posts and pagination
    .post-index-gallery {
      display: flex;
      flex-flow: column;
      gap: 1rem;

      flex: 1; // See Exhibit A

      .posts-container {
        flex: 1; // See Exhibit A
        grid-auto-rows: min-content;
      }
    }
  }

  // 3. Sidebar
  .sidebar {
    grid-area: sidebar;

    display: flex;
    flex-flow: column;
    gap: 1em;

    padding: 0.5rem 0.25rem;
    background-color: #152f56;
    background-color: themed("color-foreground");
    box-shadow: inset 0px 0.25rem 0.25rem -0.25rem themed("color-background");

    // Mode selection
    #mode-box-mode, #mode-box #set-id {
      width: 100%;

      // Match the searchbox
      padding: 0.5em;
      font-family: Verdana, sans-serif;
      font-size: 1.05em;
    }
  }
}


// Desktop
.post-index {
  @include window-larger-than(50rem) {
    grid-template-areas:
      "search  content" 
      "sidebar content";
    grid-template-columns: 14rem 1fr;
    grid-template-rows: min-content 1fr;

    .search {
      box-shadow: inset -0.25rem 0px 0.25rem -0.25rem themed("color-background");
      margin-top: 0.25rem;
      border-top-left-radius: 0.25rem;
      padding: 0.5rem;

      .search-controls {
        display: flex;
        margin-top: 0.5rem;
      }
    }

    .sidebar {
      box-shadow: inset -0.25rem 0px 0.25rem -0.25rem themed("color-background");
      margin-bottom: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      padding: 0.5rem
    }

    .content {
      border-radius: 0.25rem;
    }
  }
}


// Fullscreen
body.c-posts.a-index[data-st-fullscreen="true"] {
  // Desktop-only, for obvious reasons
  @include window-larger-than(50rem) {
    .post-index {
      grid-template-areas:
        "search " 
        "content";
      grid-template-columns: 1fr;

      .search {
        display: flex;

        border-radius: 0.25rem 0.25rem 0 0;
        box-shadow: inset 0px -0.25rem 0.25rem -0.25rem themed("color-background");

        .post-search {
          flex: 1;
        }

        .search-controls {
          display: flex;
          justify-content: right;
          align-self: end;
          margin: 0 0 0 0.5rem;

          .st-button.w100 {
            width: unset;
            span { display: none; }
          }
        }
      }
      .sidebar { display: none; }
      .content {
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }
  }
}


// FEATURES
// Wiki Excerpt
.wiki-excerpt {
  display: flex;
  flex-flow: column;
  position: relative;

  background: themed("color-section");
  border-radius: 0.25rem;

  &.hidden { display: none; }

  // header
  h3 {
    display: flex;
    align-items: center;

    font-size: 1rem;
    padding: 0.5rem;

    cursor: pointer;

    & > svg {
      transition: transform 0.25s;

      height: 1.5rem;
      width: 1.5rem;
    }

    .wiki-excerpt-dismiss { margin-left: auto; }
  }

  // body
  .styled-dtext {
    background: linear-gradient(to top, themed("color-section"), themed("color-text"));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    min-height: 0rem;
    max-height: 0rem;
    max-width: 50rem;
    overflow: hidden;
    padding: 0 0.5rem;

    transition: max-height 0.25s;

    // Disable links
    pointer-events: none;
    cursor: unset;

    a {
      color: unset;
      text-decoration: underline;
      &::after { content: none; }
    }

    p:last-child { margin-bottom: 0; }
  }

  // wiki link
  .wiki-excerpt-readmore {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: 3rem;
    max-width: 50rem;
    box-sizing: border-box;

    // Makes the button appear in the middle of the animation
    transition: visibility 0s 0.125s;
    visibility: hidden;

    span {
      padding: 0.5rem 1rem;
      background: themed("color-section");
      border-radius: 0.25rem;
    }
  }

  &.open{
    .wiki-excerpt-toggle svg { transform: rotate(90deg); }
    .styled-dtext {
      max-height: 10rem;
      min-height: 2rem;
    }
    .wiki-excerpt-readmore { visibility: visible; }
  }

  &.loading {
    h3::after, .styled-dtext { transition: none; }
  }
}
